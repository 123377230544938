import axios from 'axios';
// 是否是开发环境
const isDev = process.env.NODE_ENV === 'development';
console.log('是否是开发环境', isDev);
// const baseURL = isDev ? '/api_dev' : '/api';
const baseURL = 'https://c2c.w3frontpioneer1033.top/api/';
// 创建一个 axios 实例
const apiClient = axios.create({
    // baseURL: process.env.VUE_APP_API_BASE_URL || '/api', // 使用环境变量配置 API 基础 URL
    baseURL: baseURL, // 使用环境变量配置 API 基础 URL
    headers: {
        'Content-Type': 'application/json',
    },
});

export function request(func, data={}, method='get') {
    method = method.toLowerCase();
    if (method === 'get') {
        return apiClient.get(func, { params: data }).then(response => response.data).catch(error => {
            console.log('Error fetching data:', error);
            throw error;
        });
    } else if (method === 'post') {
        return apiClient.post(func, data).then(response => response.data).catch(error => {
            console.log('Error posting data:', error);
            throw error;
        });
    }
}
// 定义接口请求函数
export function fetchData(func) {
    return apiClient.get(func)
        .then(response => response.data)
        .catch(error => {
            console.log('Error fetching data:', error);
            throw error;
        });
}

export function postData(func, data) {
    return apiClient.post(func, data).then(response => response.data).catch(error => {
        console.log('Error posting data:', error);
        throw error;
    });
}
